import React from "react";

function LDSLoadingSpinner() {
    return <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
}

export default LDSLoadingSpinner;
